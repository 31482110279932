body {
  margin: 0;
  background: #151616;
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow:hidden;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Matrix Font NFI', 'Oswald',
    monospace;
}

:root {
  --bg:  #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 14px;
  --speed: 500ms; 
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;;
}

@font-face {
  font-family: 'MatrixCode';
  src: url('./fonts/MATRIX CODE NFI.OTF') format('opentype');
}

@font-face {
  font-family: 'DatDot';
  src: url('./fonts/DATDOT-REGULAR.TTF') format('truetype');
}