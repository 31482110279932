/* Media queries for responsiveness */
@media (max-width: 768px) {
  .left-overlay, .right-overlay, .latest-updates, .aboutMeAndOverlay {
    display: none;
  }
  .main-content {
    width: 100%;
  }
}

/* App component styling */
.App {
  text-align: center;
}

/* App logo styling */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* App header styling */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* App link styling */
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App content styling */
.appContent {
  height: 100%;
  width: 100%;
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Align children vertically */
  align-items: flex-start; /* Align children at the top of the container */
  justify-content: flex-start; /* Distribute extra space evenly */
  overflow: visible;
}

/* App page styling */
.appPage {
  height: 100%;
  width: 100%;
  z-index: 1030;
  position: fixed;
  display: flex;
  flex-direction: row; /* Align children horizontally */
  align-items: flex-start; /* Align children at the top of the container */
  justify-content: space-between; /* Space between LatestUpdates and AboutMeAndOverlay */
  overflow: visible;
  padding-top: 61px; /* Ensure there is enough top padding if needed */

}
.icon {
  width: 24px; /* Adjust this size to match your SVG icons */
  height: 24px; /* Adjust this size to match your SVG icons */
}

.matrixCamPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh; /* Full height of viewport */
  background-color: black;
  padding: 0;
  margin: 0;
}

/* Container styling */
.container {
  position: relative; /* This will be the reference for the absolute positioning of fadeAnimation */
  width: 100%; /* Take the full width */
  display: flex; /* Use flex to accommodate the dynamic height of children */
  flex-direction: column; /* Stack children vertically */
}

/* About Me and Overlay styling */
.aboutMeAndOverlay {
  display: flex;
  flex-direction: column; /* Stack AboutMe and RightOverlay vertically */
  align-items: flex-end; /* Align children to the end (right) */
  width: 30%; /* Adjust the width as needed */
  position: relative; /* Positioning it absolutely to place it at a specific spot */
  right: 0; /* Align to the right of the parent container */
  z-index: 1030; /* Ensure it's layered correctly */
  height: 100%; /* Fixed height for testing */
  overflow: visible;
}

/* Fade animation styling */
.fadeAnimation {
  position: absolute;
  opacity: 0;
  border-left: var(--border);
  border-right: var(--border);
  border-color: rgba(240, 240, 240, 0.158);
  height: 12%;
  width: 100%;
  background: repeating-linear-gradient(
    0deg,
    rgba(43, 177, 87, 0.35),
    rgba(43, 177, 87, 0.15) 1px,
    transparent 1px,
    transparent 4px
  );
  animation: fadeMoveUpDown 34s ease-in-out infinite 6s forwards;
  z-index: 1030;
}

@keyframes fadeMoveUpDown {
  0%, 97.06%, 100% { /* Start, just before end, and end of the animation cycle */
    opacity: 0;
    top: 0; /* Stay at the top */
  }
  2.94% { /* Approximately 1 second into the animation */
    opacity: 1;
    top: 88%; /* Move down to 88% of the parent's height */
  }
  5.88% { /* Approximately 2 seconds into the animation */
    opacity: 0;
    top: 0; /* Move back up */
  }
}

input, textarea {
  font-size: 16px; /* Ensures proper rendering and prevents zoom on focus */
}