/* ProjectCarousel.module.css */
.carouselContainer {
    position: relative;
    opacity: 0;
    top:0;
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    bottom: 0; /* Align to the bottom */
    z-index: 1030;
    margin: auto; /* This will help in centering */
    height: 400px; /* Height of the carousel */
    color: rgb(255, 255, 255);
    border: var(--border);
    border-color: rgba(240, 240, 240, 0.158);
    background: radial-gradient(circle at center, rgba(2, 221, 75, 0.2) 10%, rgba(93, 142, 108, 0.3) 25%, rgba(33, 79, 48, 0.5) 55%, rgba(20, 20, 20,1) 100%);
    display: flex; /* Use flex to align children */
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Center children vertically */
    display: flex;
    overflow: hidden; /* Hide the overflow */
    animation: slideUp 1.75s ease-in 1.25s forwards /* Fade in after 2 seconds over 2 seconds */
}
  

@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

  .projectSlide {
    position: relative;
    width: 100%;
    opacity: 0;
    display: block; /* Hide slides by default */
    text-align: center;
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Center children vertically */
    transition: transform 1s, opacity 0.5s; /* Smooth transition for transform and opacity */   
}
  
  .projectImage {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    overflow:auto;
    max-width: 200px; /* Adjust as needed */

  }

  .activeSlide {
    height:50%;
    width:50%;
    position: absolute;
    display: flex;
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Center children vertically */
    opacity: 1;
    z-index: 2;
    transform: translateX(0%); /* Center the slide */
    background: radial-gradient(circle at center, rgba(255, 255, 255, 0) 0%, rgba(52, 244, 116, 0.2) 30%, rgba(35, 121, 29, 0.0) 50%, rgba(157, 157, 157, 0.0) 100%);

}
  
  .sideSlide {
    
    opacity: 0.5;
    transform: scale(0.8); /* Slightly smaller size */
  }
  
  .leftSlide {
    transform: translateX(-100%) translateY(0%) scale(0.5); /* Move to the left and scale down */
  }
  
  .rightSlide {
    transform: translateX(100%) translateY(0%)scale(0.5); /* Move to the right and scale down */
  }
  
  .carouselInfo {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 40px;
    text-align: center;
  }
  
  .prevButton,
  .nextButton {
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-80%);
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .prevButton {
    left: 10px;
  }
  
  .nextButton {
    right: 10px;
  }

  /* specific image changes */
  .gladosImage {
    width: 70%;
    max-width: 100px; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
  }
  