/* MainContent.module.css */
.screen {
    position: relative;
    z-index: 1030;
    height: auto;
    width: auto;
    background: radial-gradient(circle at center, transparent, rgba(17, 17, 17, 0.5) 0%, rgb(20, 20, 20));
    border: var(--border);
    flex: 2;
    animation: slideIn 2s ease-in-out 4s forwards;
    opacity: 0;
    overflow: hidden;
    margin-right: 2px;
}

/* Animation for sliding down */
@keyframes slideIn {
    from {
        opacity: 0;
        height: 0%;        
    }
    to {
        height: 100%;
        opacity: 1;
    }
}

.whiteNoise {
    position: absolute;
    z-index: 1031;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    overflow: hidden;
    background: repeating-linear-gradient(
      0deg,
      rgba(43, 177, 87, 0.15),
      rgba(43, 177, 87, 0.05) 1px,
      transparent 1px,
      transparent 4px
    );
    animation: rolling infinite ease-in-out 4s forwards;
    pointer-events: none;
}

@keyframes rolling {
    from {
        transform: translateY(0);
        height: 100%;        
    }
    to {
        transform: translateY(infinite);
        height: 800%;
    }
}

/* Applied when the component is running the animation */
.running {
    animation: slideIn 1.2s ease-in forwards;
}

/* Content */
.contentContainer {
    width: 100%;
}

.content {
    position: relative;
    height: 100%;
    padding: 20px 20px 20px 20px;
    z-index: 1032;
    width: 100%;
}

.contentText {
    position: relative;
    color: rgb(0, 255, 170);
    max-height: 85vh; /* Adjust as needed */
    width: auto;
    padding: 20px 20px 0px 20px;
    overflow-y: auto;
    max-width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.contentText::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
}

.mobilePadding {
    padding-bottom: 60px; /* Add extra padding at the bottom only for mobile */
}

/* Input field */
.inputContainer {
    /* This container will only contain the input field */
    height: 100%; /* Keep the full height */
    width: 100%;
    position: relative; /* Position relative to allow absolute positioning inside */
    z-index: 1033; /* Ensure it's above the whiteNoise */
}

.terminalInput {
    width: 100%; /* Adjust width based on padding */
    height: 100%;
    padding: 0px 20px; /* Remove padding to align text to top left */
    border: none;
    background: transparent; /* Transparent background */
    color: rgb(0, 255, 170);
    position: absolute; /* Absolute positioning to stick to the top left of the inputContainer */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    z-index: 1034; /* Above the inputContainer */
    line-height: normal; /* Adjust line height as needed */
    resize: none; /* Remove the resize handle from textarea */
    overflow: hidden; /* Hide the overflow */
    box-sizing: border-box; /* Include padding in the width and height calculations */
}

.terminalInput:focus {
    outline: none; 
}

.mobileInputContainer {
    height: auto; /* Adjust to the height of the content */
    padding-bottom: 50px; /* Add some padding at the bottom */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1040; /* Ensure it's above other elements */
}
