.dropdown {
    position: absolute;
    top: 58px;
    width: 328px;
    transform: translateX(-45%);
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 1rem;
    overflow: hidden;
    transition: height var(--speed) ease;
    z-index: 5000;
}
  
.menu {
    width: 100%;
    
}
  
.menuItem { /* Changed from .menu-item to .menuItem */
    margin-top: 8px;
    margin-bottom: 8px;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.5rem;
    width: 312px;
}

/* Resize Icons */
.menuItem svg {
    height: 20px;
    width: 20px;
}
  
.iconButton {
    margin-right: 1rem;
}
  
.iconButton:hover {
    filter: none;
}
  
.menuItem:hover {
    background-color: #525357;
}

/* Style to Adjust Icons to the Right side */
.iconRight {
    margin-left: auto;
}
  
/* CSSTransition classes */
.menuPrimaryEnter {
    position: absolute;
    transform: translateX(-110%);
}
.menuPrimaryEnterActive {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.menuPrimaryExit {
    position: absolute;
}
.menuPrimaryExitActive {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
}
  
.menuSecondaryEnter {
    transform: translateX(110%);
}
.menuSecondaryEnterActive {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.menuSecondaryExitActive {
    transform: translateX(110%);
    transition: all var(--speed) ease;
}

/* Secondary menu */
.secondaryMenu{
    padding-bottom: 13px;
}

/* Widgits */
.widgit {
    display: flex;
    justify-content: center;
    border-radius: 14px;
    overflow: hidden;
    align-items: center;
    height: 100%;
  }
  
  .widget a {
    text-align: center;
    display: block;
  }

  .amazonList {
    list-style-type: none;
    padding-left: 0;
    text-align: left;
    margin: 0;
  }
  
  .amazonList li {
    margin-bottom: 5px;
  }
  
  .secondaryMenu h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: rgb(100, 255, 100
    ); /* Make headers white */
  }
  
  .secondaryMenu {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  /* Hide vertical scrollbar but allow scrolling */
  .secondaryMenu::-webkit-scrollbar {
    width: 0;
  }
  
  .secondaryMenu {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }