.navbar {
    position: fixed;
    top: 0; /* Adjust as needed */
    right: 0;
    z-index: 15000;
    width:100%;
    height: var(--nav-size);
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: var(--border);
    animation: fadeIn 1.8s ease-in-out 0s forwards; /* Start after 0 seconds, lasts 3 seconds */
    box-shadow: 0px 15px 5px rgba(21, 22, 22, 0.2);

}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
  

.navbarVisible {
  top: 0; /* Slide to the regular position */
}
.navbarNav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

.navbar h1{
  position: absolute;
  top: -15%;
  left:4%;
  color:white;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif  }

.logo {
  font-size: 1.5rem;
  margin-top: 26px;
  margin-left: 20px;
  color: white;
}

@media (max-width: 768px) {
  .logo {
    font-size: 2rem;
  }
  .navbar {
    padding: 5px;
  }
  .navbar img {
    width: 18px;
    height: 18px;
  }
}