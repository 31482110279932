

.slidingOverlayLeft {
  position: absolute;
  top: 0; /* Adjust as needed */
  left: 0;
  width: calc(70%); /* Assuming right overlay takes 30% */
  height: 0%;
  background: linear-gradient(to bottom, #151616 50%, transparent 100%);
  z-index: 1020;
  opacity: 0;
  
  }


/* Define the animation states for the sliding overlay */
.startLeft {
animation: none;
}

.runningLeft {
opacity: 1;
animation: slideDownLeft 2.75s ease-in 2.3s forwards;
}

.doneLeft {
opacity: 1;
height: 100%;
}

@keyframes slideDownLeft {
  0% {
    background: linear-gradient(to bottom, #151616 0%, transparent 100%);
    top: 0px;
    height: 0%;
  }
  10% {
    background: linear-gradient(to bottom, #151616 10%, transparent 100%);
    height: 10%;
  }
  20% {
    background: linear-gradient(to bottom, #151616 20%, transparent 100%);
    height: 20%;
  }
  30% {
    background: linear-gradient(to bottom, #151616 30%, transparent 100%);
    height: 30%;
  }
  40% {
    background: linear-gradient(to bottom, #151616 40%, transparent 100%);
    height: 40%;
  }
  50% {
    background: linear-gradient(to bottom, #151616 50%, transparent 100%);
    height: 50%;
  }
  60% {
    background: linear-gradient(to bottom, #151616 60%, transparent 100%);
    height: 60%;
  }
  70% {
    background: linear-gradient(to bottom, #151616 70%, transparent 100%);
    height: 70%;
  }
  80% {
    background: linear-gradient(to bottom, #151616 80%, transparent 100%);
    height: 80%;
  }
  90% {
    height: 90%;
  }
  100% {
    background: linear-gradient(to bottom, #151616 100%, transparent 100%);
    height: 100%;
  }
}