/* AboutMe.module.css */
.aboutMeContainer {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  width: 100%;
  background: radial-gradient(circle at center, transparent, rgba(21, 22, 22, 0.5) 0%, rgba(21, 22, 22, 1));
  color: white;
  box-shadow: 4px rgba(255, 255, 255, 0.5);
  border: var(--border);
  animation: fadeIn 3s ease-in-out, slideDown 3s ease-in-out forwards; 
  opacity: 1;
  position: relative; 
  height:auto;
  z-index: 1030;
}


.aboutMeFade {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  z-index: 1031; /* Ensure it's layered above the container background */
  /* background: radial-gradient(circle at center, transparent, rgba(21, 22, 22, 0.5) 0%, rgba(21, 22, 22, 1)); */
  color: white;
  opacity:1;
  position: relative;
  justify-content: flex-end;

}


.profilePhoto {
  margin-top: 20px;
  display: flex ;
  width: 50%; /* Make the width responsive to the container size */
  height: auto; /* Maintain the aspect ratio */
  max-width: 350px; /* Maximum width */
  max-height: 350px;
  border-radius: 0%; /* Circular photo */
  object-fit: cover; /* Ensure the photo covers the area without stretching */
  border: 3px solid #fff; /* Optional: adds a border around the photo */
  box-shadow: 0px 0px 10px rgb(255, 255, 255); /* adds a subtle glow */
  margin-bottom: 28px;
  opacity: 0;
  animation: fadeIn 2.5s ease-in-out 3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    top: -100%;
  }
  to {
    top: 0%;
  }
}
