/* WelcomeAnimation.module.css */
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.welcomeText {
  font-family: 'Oswald';
  font-size: 4rem;
  color: rgb(0, 255, 170);
  opacity: 0;
  z-index: 1010;
  animation: meltText 2s forwards;
  text-shadow: 0px 0px 10px rgba(0, 255, 255, 0.2); /* Optional: adds a subtle shadow */

}

@keyframes meltText {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: scale(3.5);
  }
  100% {
    opacity: 0;
    transform: translateY(100vh);
  }
}
