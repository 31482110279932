.latestUpdates {
  position: relative;
  width: 100%;
  max-width: 150px;
  background-color: rgba(0, 255, 170, 0.5);
  margin-right: 4px;
  box-shadow: 4px 0px 5px rgba(28, 206, 170, 0.2);
  padding: 10px 10px 0 10px;
  z-index: 1030;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
  animation: slideDown 1s ease-in-out;
  opacity: 1;
}

.latestUpdates ul {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden; /* Remove horizontal scrollbar */
  padding-right: 10px; /* Add padding to allow space for the scroll bar */
  margin-bottom: 10px; /* Add space at the bottom for the last bubble */
}

.latestUpdates h2 {
  background-color: rgba(21, 22, 22, 1);
  border: var(--border);
  border-radius: var(--border-radius);
  border-color: black;
  padding: 10px;
  text-align: center;
  box-shadow: 5px 5px 5px black;
  color: azure;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  margin: 0 0 10px 0;
}

.latestUpdates li {
  margin-bottom: 5px;
  padding: 5px;
  border-radius: var(--border-radius);
  background-color: #666;
  box-shadow: 5px 5px 5px black;
  border: var(--border);
  border-color: #161616;
  cursor: pointer;
  transition: background-color 0.3s ease;
  word-wrap: break-word; /* Ensure text wraps inside the bubble */
  white-space: normal; /* Ensure text wraps inside the bubble */
}

.latestUpdates li:last-child {
  margin-bottom: 20px; /* Extra space for the last bubble */
}

.latestUpdates li:hover {
  background-color: #555;
}

.updateContent {
  padding-left: 12px;
}

.updateTitle {
  padding-top: 10px;
  color: #161616;
  font-weight: bold;
}

.updateDate {
  display: block;
  color: #161616;
  font-size: 0.8em;
}

.paginationControls {
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(21, 22, 22, 0.9);
  padding: 10px;
  box-shadow: 0px -5px 5px black;
  flex-shrink: 0;
  margin-bottom: 10px; /* Add space between pagination controls and bottom */
}

.paginationControls button {
  background-color: #333;
  border-radius: 15px;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.paginationControls button:hover {
  background-color: #555;
}

.paginationControls button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

.paginationControls span {
  color: #fff;
}

.fadeAnimation {
  position: absolute;
  opacity: 0;
  border-left: var(--border);
  border-right: var(--border);
  border-color: rgba(240, 240, 240, 0.158);
  height: 12%;
  width: 100%;
  background: repeating-linear-gradient(
    0deg,
    rgba(43, 177, 87, 0.35),
    rgba(43, 177, 87, 0.15) 1px,
    transparent 1px,
    transparent 4px
  );
  animation: fadeMoveUpDown 34s ease-in-out infinite 6s forwards;
  z-index: 1030;
}

@keyframes fadeMoveUpDown {
  0%, 97.06%, 100% {
    opacity: 0;
    top: 0;
  }
  2.94% {
    opacity: 1;
    top: 88%;
  }
  5.88% {
    opacity: 0;
    top: 0;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    height: 0%;
  }
  to {
    opacity: 1;
    height: calc(100vh - 65px);
  }
}

.latestUpdates ul {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden; /* Remove horizontal scrollbar */
  padding-right: 10px; /* Add padding to allow space for the scroll bar */
  margin-bottom: 10px; /* Add space at the bottom for the last bubble */
}

.latestUpdates ul::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}