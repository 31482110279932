.slidingOverlayRight {
  position: relative;
  top: 0%;
  width: 100%;
  background: linear-gradient(to bottom, #151616 50%, transparent 100%);
  z-index: 1020;
  opacity: 0;
  padding-left:2px;

}



/* Define the animation states for the sliding overlay */
.startRight {
    animation: none;
}
    
.runningRight {
    opacity: 1  ;
    animation: slideDownRight 2.8s ease-in 4s forwards;
}

.doneRight {
    opacity: 1;
}


@keyframes slideDownRight {
  0% {
    background: linear-gradient(to bottom, #151616 0%, transparent 100%);
    top: 0%;
    height: 0%;
  }
  10% {
    background: linear-gradient(to bottom, #151616 10%, transparent 100%);
    height: 10%;
  }
  20% {
    background: linear-gradient(to bottom, #151616 20%, transparent 100%);
    height: 20%;
  }
  30% {
    background: linear-gradient(to bottom, #151616 30%, transparent 100%);
    height: 30%;
  }
  40% {
    background: linear-gradient(to bottom, #151616 40%, transparent 100%);
    height: 40%;
  }
  50% {
    background: linear-gradient(to bottom, #151616 50%, transparent 100%);
    height: 50%;
  }
  60% {
    background: linear-gradient(to bottom, #151616 60%, transparent 100%);
    height: 60%;
  }
  70% {
    background: linear-gradient(to bottom, #151616 70%, transparent 100%);
    height: 70%;
  }
  80% {
    background: linear-gradient(to bottom, #151616 80%, transparent 100%);
    height: 80%;
  }
  90% {
    background: linear-gradient(to bottom, #151616 90%, transparent 100%);
    height: 90%;
  }
  97% {
    background: linear-gradient(to bottom, #151616 90%, transparent 100%);
    height: 97%;
  }
  100% {
    background: linear-gradient(to bottom, #151616 100%, transparent 100%);
    height: 100%;
  }
}