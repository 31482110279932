
/* Text under Image */
.descriptionSection {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    width: 100%;
    border: var(--border);
    color: rgb(0, 255, 170);
    background: radial-gradient(circle at center, transparent, rgba(21, 22, 22, 0.5) 0%, rgba(21, 22, 22, 1));    box-shadow: 4px rgba(255, 255, 255, 0.5);
    opacity: 0; /* Start invisible */
    position: relative;
    z-index: 1030;
    animation: fadeInText 1.75s ease-in 1.25s forwards /* Fade in after 2 seconds over 2 seconds */
}
  

@keyframes fadeInText {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}